.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.image-container {
  margin-bottom: 20px;
}

.text-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
  text-align: left;
  padding: 1rem;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .text-wrapper {
    width: 100%; /* Make sure it takes the full width */
    text-align: left; /* Text starts from the left on smaller screens */
    margin-top: 0;
    padding: 0 10px; /* Optional: Add slight padding if needed */
  }
}
