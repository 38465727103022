body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
main{
  min-height: 80vh;
  background-color: #001122;;
}

.loaderText{
  place-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 5rem;
  font-weight: 400;
}

.loader {
  perspective: 1000px;
  -webkit-perspective: 1000px;
}
.loader > span {
  display: inline-block;
  color: #fff;
  transform-style: preserve-3d;
  transform-origin: 0 100%;
  animation: anim 3s infinite linear;
}

@keyframes anim {
  35% {
    transform: rotateX(360deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
